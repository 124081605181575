import React, {useEffect, useState} from 'react';

import { Link, useParams, useHistory } from 'react-router-dom';

import API from "../Api.js";
import MetaTags from 'react-meta-tags';
import DOMPurify from 'dompurify'; 

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppBarColor from "../AppBarColor.js";
import AppBarDesktop from "../AppBarDesktop.js";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block"
	},
	media: {
	    height: 170,
	},
}));

export default function Home() {

	const classes = useStyles();
	let { name } = useParams();
	let history = useHistory();

	const [DataArticle, setDataArticle] = useState([]);

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		initiateData(name)

	}, [])

	function initiateData(name) {
		
		fetch(API.url + "articles/detail", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"slug": name
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result)
			  	setDataArticle(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  	alert('No Promo Found')
			}
		)

	}

	return (
		<>
			<MetaTags>
	            <title>BNI Mobile Site - { DataArticle.title }</title>

	            <meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

				<meta name="twitter:title" content="BNI Mobile Site - Home" />
				<meta name="twitter:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta name="twitter:image" content="/images/bg-top1.jpg" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:site_name" content="BNI Mobile Site - Home" />
				<meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

	        </MetaTags>
			<div>
				<AppBarDesktop/>
				<Grid container spacing={3} style={{marginLeft: 110, marginTop: 10}}>
					<Grid item xs={2} style={{textAlign: 'left'}}>
						<Link onClick={() => history.goBack()} style={{textDecoration: 'none', color: '#000'}}>
							<Grid container spacing={3}>
						        <Grid item xs={2} style={{paddingTop: 15}}>
						          	<ArrowBackIcon />
						        </Grid>
						        <Grid item xs={4}>
						        	<Typography variant="h6">
										 Back
									</Typography>
						        </Grid>
					        </Grid>
				        </Link>
					</Grid>
					<Grid item xs={7}>

						<img alt="" src={ DataArticle.featured } style={{width: '100%'}}/>
				        <div className={classes.needspacesmall}/>
				        <Typography variant="h6">
							{ DataArticle.title }
						</Typography>
						<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
						<Grid container>
							<Grid item xs={1}>
								<TodayIcon /> 
							</Grid>
							<Grid item xs={11}>
								<Typography variant="subtitle1">
									{ DataArticle.published_time }
								</Typography>
							</Grid>
						</Grid>
						<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 } }/>
						<div className={classes.needspacesmall}/>
						<Typography variant="subtitle1" style={{fontFamily: 'Montserrat'}}>
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(DataArticle.content) }} />
						</Typography>
						<br/>
						<br/>

					</Grid>
				</Grid>
			</div>
        </>
	)
}