import React, {useEffect, useState} from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";
import history from '../history';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import AppBarColor from "../AppBarColor.js";
import AppBarDesktop from "../AppBarDesktop.js";
import CardListDesktop from "../Promo/CardListDesktop.js";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    height: 250,
	    backgroundSize: 'contain',
			width: '100%',
	},
}));

const AntTabs = withStyles({
  root: {
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#FF6600',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(3),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#FF6600',
      opacity: 1,
    },
    '&$selected': {
      color: '#FF6600',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#FF6600',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


export default function Lists() {

	const classes = useStyles();

	const query = new URLSearchParams(useLocation().search);

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		listposts(query.get('q'))

	}, [])

	//API
	const [DataPost, setDataPost] = useState([]);

	function listposts(q)
	{
		console.log(q);

		const id = browserID();

		fetch(API.url + "posts/search", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"limit": "20",
			    "offset": "",
			    "category_id": "",
			    "category_name": "",
			    "keyword": q,
			    "region_id": ""
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataPost(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<MetaTags>
	            <title>BNI Mobile Site - Promo 50%</title>

	            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

	            <meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

				<meta name="twitter:title" content="BNI Mobile Site - Home" />
				<meta name="twitter:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta name="twitter:image" content="/images/bg-top1.jpg" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:site_name" content="BNI Mobile Site - Home" />
				<meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

	        </MetaTags>
			<div>
				<AppBarDesktop/>
				
				<Grid container spacing={3} style={{marginLeft: 110, marginTop: 10}}>
					<Grid item xs={12}>
						
						{DataPost.map(item => (

		        			<CardListDesktop 
		        				slug={item.slug}
		        				featured={item.featured}
		        				title={item.title}
		        				subtitle={item.subtitle}
		        				period={item.period}
		        			/>

				        ))}

					</Grid>
				</Grid>

	        </div>
        </>
	)
}