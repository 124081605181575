import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import SearchIcon from '@material-ui/icons/Search';

import { Link, useLocation } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuClose from '@material-ui/icons/Close';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import Sheet from 'react-modal-sheet';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import AppBarDesktop from "../AppBarDesktop.js";
import CardList from "./CardList.js";
import CardListDesktop from "./CardListDesktop.js";
import NewsList from "./NewsList.js";

import ScrollContainer from 'react-indiana-drag-scroll';

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    needspace: {
    	marginTop: theme.spacing(4),
    },
    slider: {
        maxWidth: '100%',
        borderRadius: 10,
    },
    search: {
        marginTop: theme.spacing(5),
        width: '80%',
        color: '#fff'
    },
    dots: {
    	// textAlign: 'left',
    	marginLeft: '-9px',
	    bottom: 0,
	    "& li.slick-active button::before": {
	      	content: "url(" + process.env.PUBLIC_URL + "/sliders/dot-active.png)",
	      	transform: "scale(.4)",
	      	marginTop: "20px",
	      	opacity: 1
	   	},
	    "& li": {
	      "& button::before": {
		      	content: "url(" + process.env.PUBLIC_URL + "/sliders/dot-gray.png)",
		      	transform: "scale(.6)",
		      	marginTop: "25px",
		      	opacity: 1
		    }
		}
	},
	sliderDaerah: {
		objectFit: 'cover',
		objectPosition: 'center',
		height: '130px',
		width: '130px',
		borderRadius: '50%',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	divDaerah:{
		textAlign: 'center' 
	}
}));

let dragging = false;

export default function Home() {

	const id = browserID();
	console.log('id: '+id)

	const query = new URLSearchParams(useLocation().search);
	// let videoPosition = query.get('video');
	let videoPosition = 'bottom';

	const classes = useStyles();

	const [isOpen, setOpen] = React.useState(false);

    const setting_sliders = {
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      dotsClass: `slick-dots ${classes.dots}`,
      arrows: false,
      autoplay: true,
    };

    const setting_promo_daerah = {
      dots: false,
      infinite: false,
      speed: 100,
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      // centerMode: true,
	  responsive: [
	        {
	            breakpoint: 9999,
	            settings: {
	            	slidesToShow: 7.5,
      				slidesToScroll: 7
	            }
	        },
	        {
	            breakpoint: 768,
	             settings: {
	                    slidesToShow: 2.5,
      					slidesToScroll: 2
	                }
	        },
	        {
	            breakpoint: 360,
	             settings: {
	                    slidesToShow: 2.3,
      					slidesToScroll: 2
	                }
	        },
	        {
	            breakpoint: 320,
	             settings: {
	                    slidesToShow: 1.8,
      					slidesToScroll: 1
	                }
	        },
	    ]
    };


 //    useEffect(() => {
	//    document.title = "BNI Mobile Site"
	// }, []);

    const BNISwitch = withStyles({
		  switchBase: {
	    color: 'rgba(255,102,0, 0.3)',
	    '&$checked': {
	      color: '#FF6600',
	    },
	    '&$checked + $track': {
	      backgroundColor: '#FF6600',
	    },
	  },
	  checked: {},
	  track: {},
	})(Switch);

	const [state, setState] = React.useState({
	    checkedA: true,
	    checkedTerbaru: true,
	    checkedKategori: true,
	    checkedArtikel: true,
	});

	const handleChange = (event) => {
	    setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleChangePromoTerbaru = (event) => {
	    setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleChangeKategori = (event) => {
	    setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleChangeArtikel = (event) => {
	    setState({ ...state, [event.target.name]: event.target.checked });
	};

	//API
	const [DataSliders, setDataSliders] = useState([]);
	const [DataTheme, setDataTheme] = useState([]);
	const [DataRegions, setDataRegions] = useState([]);
	const [DataCategoryActive, setDataCategoryActive] = useState([]);
	const [DataCategoryActivePromos, setDataCategoryActivePromos] = useState([]);
	const [DataRegionPickers, setDataRegionPickers] = useState([]);
	const [DataCategoryPickers, setDataCategoryPickers] = useState([]);
	const [DataPromoTerbaru, setDataPromoTerbaru] = useState([]);
	const [DataArticles, setDataArticles] = useState([]);

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

		fetch(API.url + "users", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"key": id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  homepage();
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

	}, [])

	function themes() {
		fetch(API.url + "themes")
		.then(res => res.json())
		.then(
			(result) => {
				if(result.length < 1)
				{
					setTimeout(function(){
						themes();
					}, 500);
				}
			  console.log(result)
			  setDataTheme(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				setTimeout(function(){
					themes();
				}, 500);
			  console.log(error)
			}
		)
	}

	function homepage(){

		themes();

		fetch(API.url + "sliders")
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataSliders(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "regions", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataRegions(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "regions/nosort", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			// body: JSON.stringify({}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataRegionPickers(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "categories/active", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			// body: JSON.stringify({}),
		})
		.then(res => res.json())
		.then( async (result) => {

				for(var i = 0; i < result.length; i++)
				{
					await fetch(API.url + "posts/newest", {
						method: 'POST', // or 'PUT'
						headers: {
							'Content-Type': 'application/json',
							'userkey': id
						},
						body: JSON.stringify({
							"limit": "5",
						    "offset": "",
						    "category_id": result[i].id,
						    "region_id": ""
						}),
					})
					.then(res => res.json())
					.then((resultpromo) => {

							result[i].promo = resultpromo;
							// console.log(result);
							// console.log({"situ": result[i].promo = resultpromo})
						  	// console.log({"siini": resultpromo})


						},
						// Note: it's important to handle errors here
						// instead of a catch() block so that we don't swallow
						// exceptions from actual bugs in components.
						(error) => {
						  console.log(error)
						}
					)
				}

			  await setDataCategoryActive(result);
			  await console.log({"haha": result})
			  // setDataCategoryActive(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "categories", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			// body: JSON.stringify({}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataCategoryPickers(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "posts/newest", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"limit": "10",
			    "offset": "",
			    "category_id": "",
			    "region_id": ""
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataPromoTerbaru(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "articles", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"limit": "5"
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataArticles(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	const regionSet = (param) => {

		const id = browserID();
		console.log('id: '+id)

	    fetch(API.url + "users/set_region", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"key": id,
				"region_id": param.id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  	homepage()
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	const regionUnset = (param) => {

		const id = browserID();
		console.log('id: '+id)

	    fetch(API.url + "users/unset_region", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"key": id,
				"region_id": param.id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  	homepage()
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	const categorySet = (param) => {

		const id = browserID();
		console.log('id: '+id)

	    fetch(API.url + "users/set_category", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"key": id,
				"category_id": param.id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  	homepage()
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	const categoryUnset = (param) => {

		const id = browserID();
		console.log('id: '+id)

	    fetch(API.url + "users/unset_category", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"key": id,
				"category_id": param.id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  	homepage()
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<Fab color="primary" aria-label="Settings" style={{ position: 'fixed', bottom: 15, right: 15, zIndex: 999999, backgroundColor: 'rgba(255,102,0, 1)' }}
				onClick={() => setOpen(true)}
				>
		    	<SettingsIcon />
		    </Fab>
			<MetaTags>
	            <title>BNI Mobile Site - Home Desktop</title>

	            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

	            <meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

				<meta name="twitter:title" content="BNI Mobile Site - Home" />
				<meta name="twitter:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta name="twitter:image" content="/images/bg-top1.jpg" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:site_name" content="BNI Mobile Site - Home" />
				<meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

	        </MetaTags>
			<AppBarDesktop/>
			<Slider {...setting_sliders}>
	        	{DataSliders.map(item => (
	        		<a href={item.url} target="_blank">
	        		<div>
		                <img alt={item.name} src={item.image} className={classes.slider}/>
		                <div style={{
		                	marginTop: 0,
		                	marginLeft: 10,
		                	color: '#fff'
		                }}>
						</div>
		            </div>
		            </a>
		        ))}
	        </Slider>

	        <div style={{marginTop: 50}}/>

	        <Grid container spacing={3}>
        		<Grid item xs={8}>
        			<Typography component="span" variant="h6">
						Promo Terbaru
					</Typography>
        		</Grid>
        		<Grid item xs={4} style={{ textAlign: 'right' }}>
        			{/*<Link to="/promo/terbaru" style={{ textDecoration: 'none' }}>
	        			<Typography component="span" variant="subtitle1" style={{ color: '#056877' }}>
							Lihat Semua
						</Typography>
					</Link>*/}
        		</Grid>
        	</Grid>
        	<div className={classes.needspacesmall}/>
        	{DataPromoTerbaru.map(item => (

    			<CardListDesktop 
    				slug={item.slug}
    				featured={item.featured}
    				title={item.title}
    				subtitle={item.subtitle}
    				period={item.period}
    			/>

	        ))}

		    <div style={{marginTop: 50}}/>

		    { DataCategoryActive.map((item) => {

		    	// var promos = item.promo;
		    	if(item.promo.length > 0)
		    	{
		    		return (
    			
	        			<>
	        			<Grid container spacing={3}>
			        		<Grid item xs={6}>
			        			<Typography component="span" variant="h6">
									{item.name}
								</Typography>
			        		</Grid>
			        		<Grid item xs={6} style={{ textAlign: 'right' }}>
			        			<Link to={"/promo/"+item.slug} style={{ textDecoration: 'none' }}>
				        			<Typography component="span" variant="subtitle1" style={{ color: '#056877' }}>
										Lihat Semua
									</Typography>
								</Link>
			        		</Grid>
			        	</Grid>
			        	<div className={classes.needspacesmall}/>
			        	{ item.promo.map((promo) => {

			        		return (<CardListDesktop 
		        				slug={promo.slug}
		        				featured={promo.featured}
		        				title={promo.title}
		        				subtitle={promo.subtitle}
		        				period={promo.period}
		        			/>
		        			)
			        	})}
					    <div style={{marginTop: 50}}/>
			        	</>
			        	)
		    	}

	        })}

	        <Grid container spacing={3}>
        		<Grid item xs={6}>
        			<Typography component="span" variant="h6">
						Promo Daerah
					</Typography>
        		</Grid>
        		<Grid item xs={6} style={{ textAlign: 'right' }}>
        			<Link to="/promo-daerah" style={{ textDecoration: 'none' }}>
	        			<Typography component="span" variant="subtitle1" style={{ color: '#056877' }}>
							Lihat Semua
						</Typography>
					</Link>
        		</Grid>
        	</Grid>
        	<div className={classes.needspacesmall}/>
        	{DataRegions.map(item => (

        		<div className={classes.divDaerah} style={{display: 'inline-grid', width: '15%', textAlign: 'center', marginTop: 15}}>
		            <Link onClick={(e)=> dragging && e.preventDefault()}  to={ "/promo/" + item.slug } style={{ textDecoration: 'none' }}>
		                <img alt="" src={ item.image } className={classes.sliderDaerah}/> <br/>
		                <Typography component="span" variant="subtitle1" style={{ color: '#000', fontSize: 15 }}>
							{item.name}
						</Typography>
					</Link>
	            </div>

	        ))}

	        <div style={{marginTop: 50}}/>

	        { [0].map((item) => {
               if(videoPosition == 'bottom'){
	               return (<><center><iframe
					        width="560"
					        height="315"
					        src={"https://www.youtube.com/embed/"+ DataTheme.youtube +"?loop=1&playlist="+DataTheme.youtube }
					        frameBorder="0"
					      /></center></>
				)}

            })}

            <div style={{marginTop: 50}}/>

            <Grid container spacing={3}>
        		<Grid item xs={6}>
        			<Typography component="span" variant="h6">
						Artikel Terbaru
					</Typography>
        		</Grid>
        		<Grid item xs={6} style={{ textAlign: 'right' }}>
        			<Link to="/artikel" style={{ textDecoration: 'none' }}>
	        			<Typography component="span" variant="subtitle1" style={{ color: '#056877' }}>
							Lihat Semua
						</Typography>
					</Link>
        		</Grid>
        	</Grid>
        	<div className={classes.needspacesmall}/>
        	{DataArticles.map(item => (

        		<NewsList 
        			slug={item.slug}
        			featured={item.featured}
        			title={item.title}
        			published_time={item.published_time}
        		/>

	        ))}

            <div style={{marginTop: 50}}/>

            <Grid container spacing={3}>
	    		<Grid item xs={12} style={{ textAlign: 'center' }}>
	    			<Button 
	    				onClick={() => setOpen(true)}
	    				style={{
	    						color: '#FF6600',
	    						borderColor: '#FF6600',
	    						backgroundColor: 'rgba(255,102,0, 0.3)'}}
	    				variant="outlined" 
	    				startIcon={<SettingsOutlinedIcon style={{ color: '#FF6600' }}/>}>
			          Custom Home
			        </Button>
	    		</Grid>
	    	</Grid>
	    	<div className={classes.needspace}/>
	    	<Sheet
	    		snapPoints={[400]} 
	    		initialSnap={0}
	    		isOpen={isOpen} 
	    		onClose={() => setOpen(false)}
	    		style={{ 
	    			borderTopLeftRadius: 0,
	          		borderTopRightRadius: 0}}
	    		>
	            <Sheet.Container>
	            	<Sheet.Header>
	            		<Grid container>
	                		<Grid item xs={11} style={{paddingLeft: 10, paddingTop: 10}}>
	                			<Typography component="span" variant="h6">
	                				Custom Home
	                			</Typography>
	                		</Grid>
	                		<Grid item xs={1}>
	                			<IconButton onClick={() => setOpen(false)}>
		                            <MenuClose />
		                        </IconButton>
	                		</Grid>
	            		</Grid>
	            		<hr style={{borderColor: 'lightgray'}}/>
	              	</Sheet.Header>
					<Sheet.Content>
						<Grid container style={{paddingLeft: 15}}>
	                		<Grid item xs={10}>
	                			<Typography component="span" variant="subtitle1">
	                				Promo Daerah
	                			</Typography>
	                		</Grid>
	                		<Grid item xs={2}>
	                			{/*<FormGroup>
									<FormControlLabel
									control={<BNISwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
									label=""
									/>
								</FormGroup>*/}
	                		</Grid>
	            		</Grid>
	            		<Grid container style={{paddingLeft: 10}}>
	                		<Grid item xs={12}>
	                		{ DataRegionPickers.map(item => {

		                		if(item.id == 1){
		                			return ( 
			                			<Button variant="outlined" size="small" color="primary" style={{marginLeft: 5, marginTop: 5}}
			                				onClick={(e) => {
							            		alert('Promo Nasional tidak bisa dinonaktifkan.')
							            	}}
			                			>
								          { item.name }
								        </Button>
							        )
		                		}
		                		else if (item.active == 0) {
							        return (
							            <Button variant="outlined" size="small" color="" style={{marginLeft: 5, marginTop: 5, color: 'gray'}}
							            	onClick={ () => regionSet(item) }
							            >
								          { item.name }
								        </Button>
							        );
							    }
							    return (
							        <Button variant="outlined" size="small" color="primary" style={{marginLeft: 5, marginTop: 5}}
							        	onClick={ () => regionUnset(item) }
							        >
							          { item.name }
							        </Button>
							    );

						    })}
	                		</Grid>
	            		</Grid>
	            		{/*<Grid container style={{paddingLeft: 15, marginTop: 15}}>
	                		<Grid item xs={10}>
	                			<Typography component="span" variant="subtitle1">
	                				Promo Terbaru
	                			</Typography>
	                		</Grid>
	                		<Grid item xs={2}>
	                			<FormGroup>
									<FormControlLabel
									control={<BNISwitch checked={state.checkedTerbaru} onChange={handleChangePromoTerbaru} name="checkedTerbaru" />}
									label=""
									/>
								</FormGroup>
	                		</Grid>
	            		</Grid>*/}
	            		<Grid container style={{paddingLeft: 15, marginTop: 15}}>
	                		<Grid item xs={10}>
	                			<Typography component="span" variant="subtitle1">
	                				Kategori Promo
	                			</Typography>
	                		</Grid>
	                		<Grid item xs={2}>
	                			{/*<FormGroup>
									<FormControlLabel
									control={<BNISwitch checked={state.checkedKategori} onChange={handleChangeKategori} name="checkedKategori" />}
									label=""
									/>
								</FormGroup>*/}
	                		</Grid>
	            		</Grid>
	            		<Grid container style={{paddingLeft: 10}}>
	                		<Grid item xs={12}>
	                			{ DataCategoryPickers.map(item => {

			                		if (item.active == 0) {
								        return (
								            <Button variant="outlined" size="small" color="" style={{marginLeft: 5, marginTop: 5, color: 'gray'}}
								            	onClick={ () => categorySet(item) }
								            >
									          { item.name }
									        </Button>
								        );
								    }
								    return (
								        <Button variant="outlined" size="small" color="primary" style={{marginLeft: 5, marginTop: 5}}
								        	onClick={ () => categoryUnset(item) }
								        >
								          { item.name }
								        </Button>
								    );

							    })}
	                		</Grid>
	            		</Grid>
	            		{/*<Grid container style={{paddingLeft: 15, marginTop: 15}}>
	                		<Grid item xs={10}>
	                			<Typography component="span" variant="subtitle1">
	                				Artikel Terbaru
	                			</Typography>
	                		</Grid>
	                		<Grid item xs={2}>
	                			<FormGroup>
									<FormControlLabel
									control={<BNISwitch checked={state.checkedArtikel} onChange={handleChangeArtikel} name="checkedArtikel" />}
									label=""
									/>
								</FormGroup>
	                		</Grid>
	            		</Grid>*/}
					</Sheet.Content>
	            </Sheet.Container>

	            <Sheet.Backdrop />
	        </Sheet>
        </>
	)
}