import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuClose from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        backgroundColor: '#fff'
    },
    menuButton: {
        // marginRight: theme.spacing(2),
        color: '#000'
    },
    menuClose: {
        // marginRight: theme.spacing(2),
        color: '#fff'
    },
    title: {
        flexGrow: 1,
        marginLeft: '-5%',
        paddingLeft: 0
    },
    logo: {
        maxWidth: '90px',
        marginTop: '10px'
    },
	menulist: {
        marginTop: '10%',
        color: '#fff'
    }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}


export default function AppBarColor() {

	const classes = useStyles();

	const [modalOpen, menuOpen ] = React.useState(false);

    const handleOpen = () => {
        menuOpen(true);
    };

    const handleClose = () => {
        menuOpen(false);
    };

    const [searchOpen, setSearchOpen] = React.useState(false);
	const handleSearchOpen = () => setSearchOpen(true);
	const handleSearchClose = () => setSearchOpen(false);

    const menutext = {
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#000'
    };

	return (
		<>
			<Modal
	            open={modalOpen}
	            onClose={handleClose}
	            aria-labelledby="modal-menu"
	            aria-describedby="modal-menu"
	            style={{backgroundColor: 'rgba(0,102,119, 0.9)'}}
	        >
	            <Container maxWidth="sm" style={{backgroundColor: 'white', height: '100%'}}>
	                <AppBar elevation={0} position="static" color="transparent">
	                    <Toolbar>
	                        <Typography variant="h6" className={classes.title}>
	                            <a href="/">
	                                <img src={process.env.PUBLIC_URL + "/images/bni-logo-id.svg"} alt="Logo" className={classes.logo}/>
	                            </a>
	                        </Typography>
	                        <IconButton edge="end" className={classes.menuClose} color="inherit" aria-label="close" onClick={handleClose}>
	                            <MenuClose style={{color: '#000'}}/>
	                        </IconButton>
	                    </Toolbar>
	                </AppBar>
	                <List component="nav" aria-label="menu" className={classes.menulist}>
	                    <ListItemLink href="/">
	                        <ListItemText primary="Home" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/promo/promo50">
	                        <ListItemText primary="#Promo50%" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/promo/segera-berakhir">
	                        <ListItemText primary="#SegeraBerakhir" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/lokasi">
	                        <ListItemText primary="Lokasi BNI" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    {/*<ListItemLink href="/ecard">
	                        <ListItemText primary="e-Card BNI" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>*/}
	                    {/*<ListItemLink href="/promo/nasional">
	                        <ListItemText primary="Promo Nasional" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/promo-daerah">
	                        <ListItemText primary="Promo Daerah" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/artikel">
	                        <ListItemText primary="Artikel" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>
	                    <ListItemLink href="/sitemap">
	                        <ListItemText primary="Sitemap" primaryTypographyProps={{ style: menutext }}/>
	                    </ListItemLink>*/}
	                </List>
	            </Container>
	        </Modal>
	        <Modal
	            open={searchOpen}
	            onClose={handleSearchClose}
	            aria-labelledby="modal-menu"
	            aria-describedby="modal-menu"
	            style={{backgroundColor: 'rgba(0,102,119, 0.9)'}}
	        >
	            <Container maxWidth="sm" style={{backgroundColor: 'white', height: '100%'}}>
	            	<AppBar elevation={0} position="static" color="transparent">
	                    <Toolbar>
	                        <Typography variant="h6" className={classes.title}>
	                            
	                        </Typography>
	                        <IconButton edge="end" className={classes.menuClose} color="inherit" aria-label="close" onClick={handleSearchClose}>
	                            <MenuClose style={{color: '#000'}}/>
	                        </IconButton>
	                    </Toolbar>
	                </AppBar>
	            	<center>
	            		<form method="get" action="/search">
			                <input type="text" name="q" placeholder="Search something..." style={{
			                		marginTop: '5%',
									border: 0,
									borderBottom: '1px solid #000',
									outline: 0,
									height: 40,
									width: '80%',
									background: 'transparent',
									color: '#000',
									fontSize: 23
								}}/>
								<br/>
							<Button
								type="submit"
								style={{
										marginLeft: 10,
										marginTop: 10,
		        						color: '#FF6600',
		        						borderColor: '#FF6600',
		        						backgroundColor: 'rgba(255,102,0, 0.3)'}}
		        				variant="outlined">
		        				Search
							</Button>
      					</form>
					</center>
	            </Container>
	        </Modal>
			<AppBar elevation={0} position="static" className={classes.appbar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <a href="/">
                            <img src={process.env.PUBLIC_URL + "/images/bni-logo-id.svg"} alt="Logo" className={classes.logo}/>
                        </a>
                    </Typography>
                    {/*<Button 
                    	style={{
							marginLeft: 10,
							marginTop: 0,
    						color: '#FF6600',
    						borderColor: '#FF6600',
    						backgroundColor: 'rgba(255,102,0, 0.3)',
    						borderRadius: 20,
    						textTransform: 'capitalize',
    						padding: 3
    					}}
    					onClick={() => window.open("https://eform.bni.co.id/BNI_eForm/index.html")}
                    	variant="outlined">Apply</Button>*/}
                    <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleSearchOpen}>
                    	<SearchIcon style={{color: '#000'}}/>
                    </IconButton>
                    <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpen} style={{marginLeft: 25}}>
                    	<Grid container spacing={1}>
						  <Grid container item xs={12} spacing={3} style={{ paddingBottom: 15, textAlign: 'center', display: 'flex',  justifyContent:'center', alignItems:'center' }}>
						    <MenuIcon style={{color: '#000', transform: 'scale(1.3)'}}/>
						  </Grid>
						  <Grid container item xs={12} spacing={3} style={{ textAlign: 'center' }}>
						    <Typography variant="subtitle1" style={{color: '#000', fontSize: 7.9, marginTop:20, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
		                        MENU
		                    </Typography>
						  </Grid>
						</Grid>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
	)
}