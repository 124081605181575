import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";
import history from '../history';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppBarColor from "../AppBarColor.js";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    backgroundSize: 'contain',
			width: '100%',
	    height: 210,
	},
}));

const AntTabs = withStyles({
  root: {
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#FF6600',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(3),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#FF6600',
      opacity: 1,
    },
    '&$selected': {
      color: '#FF6600',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#FF6600',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


export default function Lists() {

	const classes = useStyles();

	let { name } = useParams();

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		// history.push(`/promo/${event.target.innerText.toLowerCase().replace(/&/g, 'and').replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
		// name = event.target.innerText.toLowerCase().replace(/&/g, 'and').replace(/ /g, '-').replace(/[^\w-]+/g, '');
		setValue(newValue);
		listposts(event.target.innerText);
	};

	useEffect(() => {
				ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		initiateData()

	}, [])

	//API

	const [TabActive, setTabActive] = useState([]);
	const [DataCategories, setDataCategories] = useState([]);
	const [DataPost, setDataPost] = useState([]);

	function initiateData() {
		
		const id = browserID();

		fetch(API.url + "categories/sortactive", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
		})
		.then(res => res.json())
		.then( (result) => {
			  console.log(result)
			  setDataCategories(result);
			  listposts("Semua")

			  for(var i = 0; i < result.length; i++)
			  {
			  	if(result[i].slug == name)
			  	{
			  		setValue((i + 1))
			  		listposts(result[i].name)
			  	}
			  }
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	function listposts(category)
	{
		const id = browserID();
		console.log(category)

		fetch(API.url + "posts/search50", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			body: JSON.stringify({
				"limit": "20",
			    "offset": "",
			    "category_id": "",
			    "category_name": "",
			    "keyword": "",
			    "region_id": ""
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataPost(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}


	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
				<Link to="/promo-daerah" style={{textDecoration: 'none', color: '#000'}}>
					<Grid container spacing={3}>
				        <Grid item xs={1} style={{paddingTop: 15}}>
				          	<ArrowBackIcon />
				        </Grid>
				        <Grid item xs={11}>
				        	<Typography variant="h6">
								 Promo
							</Typography>
				        </Grid>
			        </Grid>
		        </Link>
		        <div className={classes.needspacesmall}/>
		        {/*<AntTabs 
		        	value={value} 
		        	onChange={handleChange}
		        	variant="scrollable"
		        	>

		        		<AntTab label="Semua"/>

		        		{DataCategories.map((item, idx) => {
		        			return(
			        			<AntTab label={item.name}/>
			        		)

				        })}

		        </AntTabs>*/}
		        <div className={classes.needspacesmall}/>
				<div className={classes.needspacesmall}/>

				{DataPost.map((item, idx) => {

        			return(
	        			<Card className={classes.rootcard}>
							<CardActionArea href={ "detail/"+item.slug }>
								<CardMedia
								className={classes.media}
								image={ item.featured }
								title={ item.title }
								/>
								<CardContent>
									<Typography gutterBottom variant="subtitle2" component="p">
									{ item.title }
									</Typography>
									<Typography variant="body2" component="p">
									{ item.subtitle }
									</Typography>
									<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
									<Typography variant="body2" color="textSecondary" component="p">
										{ item.period }
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
	        		)

		        })}

	        </div>
        </>
	)
}