import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify'; 
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

const useStyles = makeStyles({
  root: {
    width: 300,
    marginRight: 10,
    minHeight: 250,
    display: "inline-block",
    borderRadius: 10,
    // transition: "transform 0.15s ease-in-out",
    // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  media: {
    height: 180,
  },
});

export default function CardList(props) {
  const classes = useStyles(); 

  let mode = '';

  if(isMobile) {
      mode = 'mobile';
  }else{
      mode = 'desktop';
  }

  return (
    <Card className={classes.root}>
    <CardActionArea href={ "artikel/detail/"+ props.slug }>
      <CardMedia
      className={classes.media}
      image={props.featured}
      title={props.title}
      />
      <CardContent>
        <Typography  variant="subtitle2" component="p" style={{minHeight: '70px'}}>
        {mode == 'desktop' ? (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.title) }} />
        ) : (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.title.replace(/(.{27})/g, "$1- <br/>") ) }} />
        )}
        </Typography>
        <Typography variant="body2" component="p">
        {/*{ props.published_time}*/}
        </Typography>
        {/*<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.period}
        </Typography>*/}
      </CardContent>
    </CardActionArea>
    </Card>
  );
}