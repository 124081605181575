import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppBarColor from "../AppBarColor.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    height: 250,
	},
	backgroundColor: theme.palette.background.paper,
}));

export default function Lists() {

	const classes = useStyles();

	let { name } = useParams();

	useEffect(() => {

		getdata()

	}, [])

	const [DataCategoryPickers, setDataCategoryPickers] = useState([]);
	const [DataRegionPickers, setDataRegionPickers] = useState([]);

	function getdata() {
		const id = browserID();

		fetch(API.url + "categories", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			// body: JSON.stringify({}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataCategoryPickers(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "regions/nosort", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
			// body: JSON.stringify({}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataRegionPickers(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
					<Link to="/" style={{textDecoration: 'none', color: '#000'}}>
						<Grid container spacing={3}>
					        <Grid item xs={1} style={{paddingTop: 15}}>
					          	<ArrowBackIcon />
					        </Grid>
				        </Grid>
			        </Link>
				    <div className={classes.root}>
						<List component="nav" aria-label="">
							<Link to="/" style={{textDecoration: 'none', color: '#000'}}>
								<ListItem
								button
								selected=""
								onClick="">
									<ListItemIcon>
							          	<ArrowRightIcon />
							        </ListItemIcon>
									<ListItemText primary="Home" />
								</ListItem>
							</Link>
							<Divider />
								<Link to="/promo/terbaru" style={{textDecoration: 'none', color: '#000'}}>
									<ListItem
									button
									selected=""
									onClick=""
									style={{paddingLeft: 40}}>
										<ListItemIcon>
								          	<ArrowRightIcon />
								        </ListItemIcon>
										<ListItemText primary="Kategori" />
									</ListItem>
								</Link>
								<Divider />
									{ DataCategoryPickers.map(item => {
										return (
											<>
											<Link to={"promo/"+item.slug} style={{textDecoration: 'none', color: '#000'}}>
												<ListItem
												button
												selected=""
												onClick=""
												style={{paddingLeft: 80}}>
													<ListItemIcon>
											          	<ArrowRightIcon />
											        </ListItemIcon>
													<ListItemText primary={item.name} />
												</ListItem>
											</Link>
											<Divider />
											</>
										)
									})}
								<Link to="" style={{textDecoration: 'none', color: '#000'}}>
									<ListItem
									button
									selected=""
									onClick=""
									style={{paddingLeft: 40}}>
										<ListItemIcon>
								          	<ArrowRightIcon />
								        </ListItemIcon>
										<ListItemText primary="Promo Daerah" />
									</ListItem>
								</Link>
								<Divider />
									{ DataRegionPickers.map(item => {
										return (
											<>
											<Link to={"promo/"+item.slug} style={{textDecoration: 'none', color: '#000'}}>
												<ListItem
												button
												selected=""
												onClick=""
												style={{paddingLeft: 80}}>
													<ListItemIcon>
											          	<ArrowRightIcon />
											        </ListItemIcon>
													<ListItemText primary={item.name} />
												</ListItem>
											</Link>
											<Divider />
											</>
										)
									})}
								<Link to="/artikel" style={{textDecoration: 'none', color: '#000'}}>
									<ListItem
									button
									selected=""
									onClick=""
									style={{paddingLeft: 40}}>
										<ListItemIcon>
								          	<ArrowRightIcon />
								        </ListItemIcon>
										<ListItemText primary="Artikel" />
									</ListItem>
								</Link>
								<Divider />
								<Link to="/lokasi" style={{textDecoration: 'none', color: '#000'}}>
									<ListItem
									button
									selected=""
									onClick=""
									style={{paddingLeft: 40}}>
										<ListItemIcon>
								          	<ArrowRightIcon />
								        </ListItemIcon>
										<ListItemText primary="Lokasi BNI" />
									</ListItem>
								</Link>
								<Divider />
									<Link to="/lokasi/atm" style={{textDecoration: 'none', color: '#000'}}>
										<ListItem
										button
										selected=""
										onClick=""
										style={{paddingLeft: 80}}>
											<ListItemIcon>
									          	<ArrowRightIcon />
									        </ListItemIcon>
											<ListItemText primary="Lokasi ATM" />
										</ListItem>
									</Link>
									<Divider />
									<Link to="/lokasi/cabang" style={{textDecoration: 'none', color: '#000'}}>
										<ListItem
										button
										selected=""
										onClick=""
										style={{paddingLeft: 80}}>
											<ListItemIcon>
									          	<ArrowRightIcon />
									        </ListItemIcon>
											<ListItemText primary="Lokasi Kantor Cabang" />
										</ListItem>
									</Link>
									<Divider />
						</List>
		    		</div>
		    		<div style={{marginTop: 100}}/>
		    </div>
        </>
	)
}