import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppBarColor from "../AppBarColor.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    height: 250,
	},
	backgroundColor: theme.palette.background.paper,
}));

export default function Lists() {

	const classes = useStyles();

	let { id } = useParams();
	const [DataContent, setDataContent] = useState([]);

	useEffect(() => {

		initiateData(id)

	}, [])

	function initiateData(id) {

		fetch(API.url + "locations/atm/detail", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				"id": id
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataContent(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
					<Link to={"/lokasi/atm"} style={{textDecoration: 'none', color: '#000'}}>
						<Grid container spacing={3}>
					        <Grid item xs={1} style={{paddingTop: 15}}>
					          	<ArrowBackIcon />
					        </Grid>
				        </Grid>
			        </Link>
			        <div style={{marginTop: 20}}/>
				    <div className={classes.root}>
						<iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyByd9dLGZMGDxk9B2QsSiE3uurMrl9gNuU&q="+DataContent.titik_koordinat+"&zoom=14"} width="100%" height="350" allowfullscreen="" style={{border:0}} loading="lazy"></iframe>
						<h4>{DataContent.lokasi}</h4>
						<p>{DataContent.alamat_lengkap}</p>
						<p>{DataContent.dati}, {DataContent.provinsi}, {DataContent.kode_pos}</p>
		    		</div>
		    		<div style={{marginTop: 100}}/>
		    </div>
        </>
	)
}