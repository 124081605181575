import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    height: 350,
    width: 250,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 10,
    minHeight: 200,
    display: "inline-block",
    borderRadius: 10,
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  media: {
    height: 150,
    backgroundSize: 'contain',
			width: '100%',
  },
});

export default function CardList(props) {
  const classes = useStyles(); 

  return (
    <Card className={classes.root}>
		<CardActionArea href={ "/promo/detail/"+ props.slug }>
			<CardMedia
			className={classes.media}
			image={props.featured}
			title={props.title}
			/>
			<CardContent>
				<Typography gutterBottom variant="subtitle2" component="p">
				{props.title}
				</Typography>
				<Typography variant="body2" component="p">
				{ props.subtitle +" ..."}
				</Typography>
				<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
				<Typography variant="body2" color="textSecondary" component="p">
					{props.period}
				</Typography>
			</CardContent>
		</CardActionArea>
    </Card>
  );
}