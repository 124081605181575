import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '19%',
    height: '19%',
    marginLeft: 10,
    marginTop: 20,
    height: 300,
    display: "inline-grid",
    borderRadius: 10,
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  media: {
    height: 150,
    borderRadius: '10px 10px 0px 0px',
    backgroundSize: 'contain',
		width: '100%',
  },
});

export default function CardList(props) {
  const classes = useStyles(); 

  return (
    <Card className={classes.root}>
		<CardActionArea href={ "promo/detail/"+ props.slug }>
			<CardMedia
			className={classes.media}
			image={props.featured}
			title={props.title}
			/>
			<CardContent>
				{<Typography gutterBottom variant="body2" color="textSecondary" component="p" style={{fontSize: 14}}>
				{props.title}
				</Typography>}
				<Typography variant="body2" component="p" style={{fontSize: 15}}>
				{ [0].map((item) => {
					if(props.subtitle.length > 60)
					{
						return(props.subtitle.substring(0, 60)+"...")
					}
					else
					{
						return(props.subtitle)
					}
				})}
				</Typography>
				{/*<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
				<Typography variant="body2" color="textSecondary" component="p" style={{fontSize: 10}}>
					{props.period}
				</Typography>*/}
			</CardContent>
		</CardActionArea>
    </Card>
  );
}