import React, {useEffect, useState} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppBarColor from "../AppBarColor.js";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    height: 220,
	},
}));

export default function Lists() {

	const classes = useStyles();

	let { name } = useParams();
	let history = useHistory();

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		initiateData()

	}, [])

	//API
	const [DataArticle, setDataArticle] = useState([]);

	function initiateData() {
		
		const id = browserID();

		fetch(API.url + "articles", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				"limit": "20"
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataArticle(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
				<Link onClick={() => history.goBack()} style={{textDecoration: 'none', color: '#000'}}>
					<Grid container spacing={3}>
				        <Grid item xs={1} style={{paddingTop: 15}}>
				          	<ArrowBackIcon />
				        </Grid>
				        <Grid item xs={11}>
				        	<Typography variant="h6">
								 Back
							</Typography>
				        </Grid>
			        </Grid>
		        </Link>
		        <div className={classes.needspacesmall}/>
		        <div className={classes.needspacesmall}/>
				<div className={classes.needspacesmall}/>

				{DataArticle.map((item, idx) => {

        			return(
	        			<Card className={classes.rootcard}>
							<CardActionArea href={ "artikel/detail/"+item.slug }>
								<CardMedia
								className={classes.media}
								image={ item.featured }
								title={ item.title }
								/>
								<CardContent>
									<Typography gutterBottom variant="h6" component="p" style={{minHeight: '70px'}}>
									{ item.title }
									</Typography>
									<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
									<Typography variant="body2" color="textSecondary" component="p">
										{ item.published_time }
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
	        		)

		        })}

	        </div>
        </>
	)
}