import React, {useEffect} from 'react';

import { BrowserRouter, Route, Switch, Link, useLocation } from 'react-router-dom';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import './App.css';
import history from './history';
import ScrollToTop from "./ScrollToTop";
import Home from './Home/Home';
import Slider from './Home/Slider';
import SliderMobile from './Home/SliderMobile';
import HomeDesktop from './Home/HomeDesktop';
import HomeSlider from './Home/HomeSlider';
import PromoDaerah from './Promo/Daerah';
import PromoDaerahDesktop from './Promo/DaerahDesktop';
import Promo50 from './Promo/Promo50';
import Promo50Desktop from './Promo/Promo50Desktop';
import PromoBerakhir from './Promo/PromoBerakhir';
import PromoBerakhirDesktop from './Promo/PromoBerakhirDesktop';
import PromoList from './Promo/Lists';
import PromoListDesktop from './Promo/ListsDesktop';
import PromoDetail from './Promo/Detail';
import PromoDetailDesktop from './Promo/DetailDesktop';
import Preview from './Promo/Preview';
import PreviewDesktop from './Promo/PreviewDesktop';
import PromoSearch from './Promo/Search';
import PromoSearchDesktop from './Promo/SearchDesktop';
import ArticleList from './Article/Lists';
import ArticleListDesktop from './Article/ListsDesktop';
import ArticleDetail from './Article/Detail';
import ArticleDetailDesktop from './Article/DetailDesktop';
import PreviewArticle from './Article/PreviewArticle';
import PreviewArticleMobile from './Article/PreviewArticleMobile';
import LokasiList from './Lokasi/Lists';
import LokasiATMList from './Lokasi/ATMLists';
import LokasiATMDetail from './Lokasi/ATMDetail';
import LokasiCabangList from './Lokasi/CabangLists';
import LokasiCabangDetail from './Lokasi/CabangDetail';
import Sitemap from './Sitemap/Lists';
import EcardDesktop from './Ecard/Desktop';
import EcardMobile from './Ecard/Mobile';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import GothamMedium from './fonts/Gotham_Medium.otf';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-PJML87N'
}
TagManager.initialize(tagManagerArgs)

const useStyles = makeStyles((theme) => ({
    appstore: {
        height: 40
    },
    googleplay: {
        height: 59,
        marginTop: -20
    },
    container: { 
        margin: '0 auto', 
        padding: 0, 
        maxWidth: '500px' 
    }
}));

const gotham = {
  fontFamily: 'GothamMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GothamMedium'),
    url(${GothamMedium}) format('otf')
  `
};

const theme = createTheme({
  typography: {
    fontFamily: 'GothamMedium, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gotham],
      },
    },
  },
});

export default function App() {

    const classes = useStyles();

    const query = new URLSearchParams(window.location.search);
    let mode = query.get('mode');
    let home = '';

    let containerClass = {}

    if(query.get('mode') == null || query.get('mode') == '' || query.get('mode') == undefined)
    {
        if(isMobile) {
            mode = 'mobile';
        }else{
            mode = 'desktop';
        }
    }

    if(mode == 'desktop')
    {
        containerClass = {}
    }
    else
    {
        containerClass = { 
            margin: '0 auto', 
            padding: 0, 
            maxWidth: '500px' 
        }
    }

    useEffect(() => {

        console.log(window.location.pathname + window.location.search)
        ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }, [])

    return (
        <>
        <ThemeProvider theme={theme}>
        <Container style={containerClass}>
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/">
                        {mode == 'desktop' ? (
                        <HomeDesktop />
                        ) : (
                        <Home />
                        )}
                    </Route>
                    <Route exact path="/slider/:name">
                        {mode == 'desktop' ? (
                        <HomeSlider />
                        ) : (
                        <HomeSlider />
                        )}
                    </Route>
                    <Route exact path="/slider_mobile/:name">
                        {mode == 'mobile' ? (
                        <SliderMobile />
                        ) : (
                        <SliderMobile />
                        )}
                    </Route>
                    <Route exact path="/promo-daerah">
                        {mode == 'desktop' ? (
                        <PromoDaerahDesktop />
                        ) : (
                        <PromoDaerah />
                        )}
                    </Route>
                    <Route exact path="/promo/daerah/:name">
                        {mode == 'desktop' ? (
                        <PromoListDesktop/>
                        ) : (
                        <PromoList />
                        )}

                    </Route>
                    <Route exact path="/promo">
                        {mode == 'desktop' ? (
                        <PromoListDesktop/>
                        ) : (
                        <PromoList />
                        )}
                    </Route>
                    <Route exact path="/promo/promo50">
                        {mode == 'desktop' ? (
                        <Promo50Desktop />
                        ) : (
                        <Promo50 />
                        )}
                    </Route>
                    <Route exact path="/promo/segera-berakhir">
                        {mode == 'desktop' ? (
                        <PromoBerakhirDesktop />
                        ) : (
                        <PromoBerakhir />
                        )}
                    </Route>
                    <Route exact path="/promo/:name">
                        {mode == 'desktop' ? (
                        <PromoListDesktop/>
                        ) : (
                        <PromoList />
                        )}
                    </Route>
                    <Route exact path="/search">
                        {mode == 'desktop' ? (
                        <PromoSearchDesktop/>
                        ) : (
                        <PromoSearch />
                        )}
                        
                    </Route>
                    <Route exact path="/promo/detail/:name">
                        {mode == 'desktop' ? (
                        <PromoDetailDesktop/>
                        ) : (
                        <PromoDetail />
                        )}
                    </Route>
                    <Route exact path="/promo/preview/:name">
                        {mode == 'desktop' ? (
                        <PreviewDesktop/>
                        ) : (
                        <Preview />
                        )}
                    </Route>
                    <Route exact path="/artikel">
                        {mode == 'desktop' ? (
                        <ArticleListDesktop/>
                        ) : (
                        <ArticleList />
                        )}
                    </Route>
                    <Route exact path="/artikel/detail/:name">
                        {mode == 'desktop' ? (
                        <ArticleDetailDesktop/>
                        ) : (
                        <ArticleDetail />
                        )}
                    </Route>
                    <Route exact path="/artikel/preview/:name">
                        {mode == 'desktop' ? (
                        <PreviewArticle />
                        ) : (
                        <PreviewArticleMobile />
                        )}
                        
                    </Route>
                    <Route exact path="/lokasi">
                        <LokasiList />
                    </Route>
                    <Route exact path="/lokasi/atm">
                        <LokasiATMList />
                    </Route>
                    <Route exact path="/lokasi/atm/:id">
                        <LokasiATMDetail />
                    </Route>
                    <Route exact path="/lokasi/cabang">
                        <LokasiCabangList />
                    </Route>
                    <Route exact path="/lokasi/cabang/:id">
                        <LokasiCabangDetail />
                    </Route>
                    <Route exact path="/sitemap">
                        <Sitemap />
                    </Route>
                    <Route exact path="/ecard">
                        {mode == 'desktop' ? (
                        <EcardDesktop />
                        ) : (
                        <EcardMobile />
                        )}
                    </Route>
                </Switch>
            </BrowserRouter>
        </Container>
        <Box mt={5} style={{ minHeight: 200, background: '#006677', margin: -8, paddingBottom: 100 }} spacing={0}>
            <Typography variant="h6" align="center" style={{ color: '#fff', textDecoration: 'underline', textUnderlinePosition: 'under', paddingBottom: 20, paddingTop: 20 }} component="p">
                Download Sekarang
            </Typography>
            <Typography variant="subtitle1" align="center" component="p" style={{ color: '#fff', paddingBottom: 20, paddingLeft: 30, paddingRight: 30 }}>
                  Dengan Rekening BNI, kamu bisa menikmati berbagai benefit dan promo menarik.
            </Typography>
            <Typography variant="subtitle1" align="center" component="p" style={{ color: '#fff', paddingBottom: 20, paddingLeft: 30, paddingRight: 30 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <a href="https://apps.apple.com/id/app/bni-mobile-banking/id967205539" target="_blank">
                        <img src={process.env.PUBLIC_URL + "/images/appstore.svg"} alt="App Store" className={classes.appstore}/>
                        </a>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'left', paddingTop: 14 }}>
                        <a href="https://play.google.com/store/apps/details?id=src.com.bni&hl=en&gl=US" target="_blank">
                        <img src={process.env.PUBLIC_URL + "/images/googleplay.png"} alt="Play Store" className={classes.googleplay}/>
                        </a>
                    </Grid>
                </Grid>
            </Typography>

            <Typography variant="h6" align="center" style={{ color: '#fff', paddingBottom: 20, paddingTop: 20 }} component="p">
                Kontak
            </Typography>

            <center>
            <a href="https://www.facebook.com/bni" style={{color: '#fff', textDecoration: 'none'}}>
            <table style={{width: 300}}>
                <tr>
                    <td style={{paddingLeft: 20, width: 50, textAlign: 'right'}}>
                        <FacebookIcon/>
                    </td>
                    <td style={{paddingLeft: 20}}>
                        <Typography variant="subtitle1" align="left" component="p" style={{ color: '#fff', fontSize: 15 }}>
                        Facebook
                        </Typography>
                    </td>
                </tr>
            </table>
            </a>

            <a href="https://twitter.com/BNI" style={{color: '#fff', textDecoration: 'none'}}>
            <table style={{width: 300}}>
                <tr>
                    <td style={{paddingLeft: 20, width: 50, textAlign: 'right'}}>
                        <TwitterIcon/>
                    </td>
                    <td style={{paddingLeft: 20}}>
                        <Typography variant="subtitle1" align="left" component="p" style={{ color: '#fff', fontSize: 15 }}>
                        Twitter
                        </Typography>
                    </td>
                </tr>
            </table>
            </a>

            <a href="tel:1500046" style={{color: '#fff', textDecoration: 'none'}}>
            <table style={{width: 300}}>
                <tr>
                    <td style={{paddingLeft: 20, width: 50, textAlign: 'right'}}>
                        <LocalPhoneIcon/>
                    </td>
                    <td style={{paddingLeft: 20}}>
                        <Typography variant="subtitle1" align="left" component="p" style={{ color: '#fff', fontSize: 15 }}>
                        BNI Call 1500046
                        </Typography>
                    </td>
                </tr>
            </table>
            </a>
            </center>
            {/*<hr/>*/}
            <center>
            <table style={{marginTop: 50}}>
                <tr>
                    <td style={{paddingLeft: 20}}>
                        <img src={process.env.PUBLIC_URL + "/images/ojk.png"} alt="OJK" style={{ width: '100px' }}/> 
                    </td>
                    <td style={{paddingLeft: 20}}>
                        <Typography variant="subtitle1" align="left" component="p" style={{ color: '#fff', fontSize: 12 }}>
                        BNI terdaftar dan diawasi oleh Otoritas Jasa Keuangan
                        </Typography>
                    </td>
                </tr>
                <tr>
                    <td style={{paddingLeft: 20, paddingTop: 0, marginTop: 0}}>
                        <img src={process.env.PUBLIC_URL + "/images/lps.png"} alt="LPS" style={{ width: '100px' }}/> 
                    </td>
                    <td style={{paddingLeft: 20}}>
                        <Typography variant="subtitle1" align="left" component="span" style={{ color: '#fff', fontSize: 12 }}>
                        BNI merupakan perserta penjaminan LPS
                        </Typography>
                    </td>
                </tr>
            </table>
            </center>

            <Typography variant="body2" align="center" component="p" style={{ color: '#fff', paddingBottom: 20, paddingLeft: 30, paddingRight: 30, paddingTop: 50 }}>
                  Copyright &copy; 2022 - PT Bank Negara Indonesia (Persero) Tbk
            </Typography>
        </Box>
        </ThemeProvider>
        </>
    );
}
