import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import AppBarColor from "../AppBarColor.js";
import AppBarDesktop from "../AppBarDesktop.js";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    height: 250,
	    width: 250,
	    marginRight: 20,
	    marginTop: 10,
	    marginBottom: 10,
	    minHeight: 200,
	    display: "inline-block",
	    borderRadius: 10
	},
	media: {
	    height: 200,
	    backgroundSize: 'contain',
		width: '100%',
	},
}));

export default function Daerah() {

	const classes = useStyles();

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

		initiateData()

	}, [])

	//API

	const [DataTheme, setDataTheme] = useState([]);
	const [DataRegions, setDataRegions] = useState([]);

	function initiateData() {
		
		const id = browserID();

		fetch(API.url + "themes")
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataTheme(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "regions", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataRegions(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<MetaTags>
	            <title>BNI Mobile Site - Promo Daerah</title>

	            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

	            <meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

				<meta name="twitter:title" content="BNI Mobile Site - Home" />
				<meta name="twitter:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta name="twitter:image" content="/images/bg-top1.jpg" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:site_name" content="BNI Mobile Site - Home" />
				<meta property="og:title" content="BNI Mobile Site - Home" />
				<meta property="og:description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et felis justo. Mauris pharetra, quam eget placerat bibendum, ligula quam congue purus, nec laoreet risus metus nec magna." />
				<meta property="og:image" content="/images/bg-top1.jpg" />
				<meta property="og:url" content="/" />

	        </MetaTags>
			<div>
				<AppBarDesktop/>
				<Grid container spacing={3} style={{marginLeft: 110, marginTop: 10}}>
					<Grid item xs={9} style={{textAlign: 'left'}}>
						<img alt="" src={ DataTheme.region_image } style={{width: '100%', borderRadius: '10%'}}/>
					</Grid>
					<Grid item xs={9} style={{textAlign: 'center'}}>
						<Typography variant="h6">
							Promo Daerah
						</Typography>
					</Grid>
					<Grid item xs={9} style={{textAlign: 'center'}}>
						{DataRegions.map(item => (

			        		<Card className={classes.rootcard} elevation={0} >
								<CardActionArea href={ "/promo/"+ item.slug }>
									<CardMedia
										className={classes.media}
										image={ item.image }
										title="Nasional"
									/>
									<CardContent style={{paddingLeft: 0}}>
										<Typography gutterBottom variant="h5" component="h2">
											{ item.name }
										</Typography>
									</CardContent>
								</CardActionArea>
							</Card>

				        ))}
					</Grid>
				</Grid>
			</div>
	        
        </>
	)
}