import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppBarColor from "../AppBarColor.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RoomIcon from '@material-ui/icons/Room';

import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block",
	    marginBottom: 20
	},
	media: {
	    height: 250,
	},
	backgroundColor: theme.palette.background.paper,
}));

export default function Lists() {

	const classes = useStyles();

	let { name } = useParams();

	useEffect(() => {

		initiateData()

	}, [])

	const [DataList, setDataList] = useState([]);
	const [DataContent, setDataContent] = useState([]);

	const handleChange = (event) => {
	    console.log(event)
	    getData(event)
	};

	function initiateData() {
		
		const id = browserID();

		fetch(API.url + "locations/atm", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			// body: JSON.stringify({
			// 	"limit": "20"
			// }),
		})
		.then(res => res.json())
		.then(
			(result) => {
			  setDataList(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	function getData(event) {
		
		const id = browserID();

		fetch(API.url + "locations/atm", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				"dati": event.value
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result)
			  setDataContent(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
					<Link to="/lokasi" style={{textDecoration: 'none', color: '#000'}}>
						<Grid container spacing={3}>
					        <Grid item xs={1} style={{paddingTop: 15}}>
					          	<ArrowBackIcon />
					        </Grid>
				        </Grid>
			        </Link>
			        <div style={{marginTop: 20}}/>
				    <div className={classes.root}>
				    	<Select 
				    		placeholder={<div>Pilih Lokasi...</div>} 
				    		options={DataList} 
				    		isSearchable={true}
				    		style={{
				    			borderRadius: '0px'
				    		}}
				    		onChange={handleChange} 
				    	/>
						<List component="nav" aria-label="">

							{DataContent.map((item, idx) => {
								return (
									<>
									<Link to={"/lokasi/atm/"+item.id} style={{textDecoration: 'none', color: '#000'}}>
										<ListItem
											button
											selected=""
											onClick="">
											<ListItemText primary={item.lokasi} />
											<ListItemSecondaryAction>
							                    <IconButton edge="end" aria-label="">
							                      	<RoomIcon />
							                    </IconButton>
							                </ListItemSecondaryAction>
										</ListItem>
									</Link>
									<Divider />
									</>
								)
							})}

						</List>
		    		</div>
		    		<div style={{marginTop: 100}}/>
		    </div>
        </>
	)
}