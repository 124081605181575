import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuClose from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        backgroundColor: '#fff'
    },
    menuButton: {
    	textTransform: 'capitalize',
	    marginRight: theme.spacing(2),
	    '&:hover': {
             textDecoration: 'underline',
             color: '#FF6600',
             borderColor: '#FF6600'
        }
	},
	title: {
		flexGrow: 1,
		marginLeft: 100
	},
	logo: {
        maxWidth: '90px',
        marginTop: '10px'
    },
    appstore: {
        height: 40
    },
    googleplay: {
        height: 59,
        marginTop: -20
    },
}));


export default function AppBarDesktop() {

	const classes = useStyles();

	const [searchOpen, setSearchOpen] = React.useState(false);
	const handleSearchOpen = () => setSearchOpen(true);
	const handleSearchClose = () => setSearchOpen(false);

	return (
		<>
			<div className={classes.root}>
		      <AppBar position="static" className={classes.appbar} elevation={0}>
		        <Toolbar>
		          <Typography variant="h6" className={classes.title}>
		            <a href="/">
                        <img src={process.env.PUBLIC_URL + "/images/bni-logo-id.svg"} alt="Logo" className={classes.logo}/>
                    </a>
		          </Typography>
		          <div style={{width: '100%', textAlign: 'left', paddingLeft: 100, paddingTop: 10}}>

		          	<Grid container spacing={1}>
		          		<Grid item xs={8}>
		          			{/*<Button href="/" className={classes.menuButton}>Home</Button>
				          	<Button href="/promo/nasional" className={classes.menuButton}>Promo Nasional</Button>
				          	<Button href="/promo-daerah" className={classes.menuButton}>Promo Daerah</Button>*/}
				          	<Button href="/promo/promo50" className={classes.menuButton}>#Promo50%</Button>
				          	<Button href="/promo/segera-berakhir" className={classes.menuButton}>#SegeraBerakhir</Button>
				          	<Button href="/promo/nasional" className={classes.menuButton}>Semua Promo</Button>
				          	<Button href="/artikel" className={classes.menuButton}>Artikel</Button>
				          	{/*<Button href="/ecard" className={classes.menuButton}>e-Card</Button>*/}
				          	{/*<Button 
	                    	style={{
								marginLeft: 10,
								marginTop: 0,
	    						color: '#FF6600',
	    						borderColor: '#FF6600',
	    						backgroundColor: 'rgba(255,102,0, 0.3)',
	    						borderRadius: 20,
	    						textTransform: 'capitalize',
	    						padding: 3
	    					}}
	    					onClick={() => window.open("https://eform.bni.co.id/BNI_eForm/index.html")}
	                    	variant="outlined">Apply</Button>*/}
		          		</Grid>
		          		<Grid item xs={4} style={{ textAlign: 'right' }}>
	                    	<Button className={classes.menuButton} onClick={handleSearchOpen}>
	                    	<SearchIcon style={{color: '#000'}}/> &nbsp;
	                    	Pencarian</Button>
	                    </Grid>
	                    {/*<Grid item xs={2} style={{ textAlign: 'right' }}>
	                    	<a href="https://apps.apple.com/id/app/bni-mobile-banking/id967205539" target="_blank">
	                        	<img src={process.env.PUBLIC_URL + "/images/appstore.svg"} alt="App Store" className={classes.appstore}/>
	                        </a>
	                    </Grid>
	                    <Grid item xs={2} style={{ textAlign: 'left', paddingTop: 14 }}>
	                    	<a href="https://play.google.com/store/apps/details?id=src.com.bni&hl=en&gl=US" target="_blank">
	                        	<img src={process.env.PUBLIC_URL + "/images/googleplay.png"} alt="Play Store" className={classes.googleplay}/>
	                        </a>
	                    </Grid>*/}
	                </Grid>
		          </div>
		        </Toolbar>
		      </AppBar>
		    </div>
		    <hr style={{border: '1px solid lightgray'}}/>

		    <Modal
	            open={searchOpen}
	            onClose={handleSearchClose}
	            aria-labelledby="modal-menu"
	            aria-describedby="modal-menu"
	            style={{backgroundColor: 'rgba(0,102,119, 0.9)'}}
	        >
	            <Container maxWidth="sm" style={{backgroundColor: 'white', height: '35%', marginTop: 20}}>
	            	<AppBar elevation={0} position="static" color="transparent">
	                    <Toolbar>
	                        <Typography variant="h6" className={classes.title}>
	                            
	                        </Typography>
	                        <IconButton edge="end" className={classes.menuClose} color="inherit" aria-label="close" onClick={handleSearchClose}>
	                            <MenuClose style={{color: '#000'}}/>
	                        </IconButton>
	                    </Toolbar>
	                </AppBar>
	            	<center>
	            		<form method="get" action="/search">
			                <input type="text" name="q" placeholder="Search something..." style={{
			                		marginTop: '5%',
									border: 0,
									borderBottom: '1px solid #000',
									outline: 0,
									height: 40,
									width: '70%',
									background: 'transparent',
									color: '#000',
									fontSize: 23
								}}/>
							<Button
								type="submit"
								style={{
										marginLeft: 10,
										marginTop: 10,
		        						color: '#FF6600',
		        						borderColor: '#FF6600',
		        						backgroundColor: 'rgba(255,102,0, 0.3)'}}
		        				variant="outlined">
		        				Search
							</Button>
      					</form>
					</center>
	            </Container>
	        </Modal>
        </>
	)
}