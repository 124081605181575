import React,{useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import browserID from 'browser-id';

import API from "../Api.js";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import AppBarColor from "../AppBarColor.js";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block"
	},
	media: {
	    height: 170,
	},
}));

export default function Daerah() {

	const classes = useStyles();
	let history = useHistory();

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

		initiateData()

	}, [])

	//API

	const [DataTheme, setDataTheme] = useState([]);
	const [DataRegions, setDataRegions] = useState([]);

	function initiateData() {
		
		const id = browserID();

		fetch(API.url + "themes")
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataTheme(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)

		fetch(API.url + "regions", {
			method: 'GET', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				'userkey': id
			},
		})
		.then(res => res.json())
		.then(
			(result) => {
			  console.log(result)
			  setDataRegions(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error)
			}
		)
	}

	return (
		<>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
				<Link to="/">
		        	<img alt="" src={ DataTheme.region_image } style={{width: '100%'}}/>
		        </Link>
		        <div className={classes.needspacesmall}/>
		        <Typography variant="h6">
					Promo Daerah
				</Typography>
				<div className={classes.needspacesmall}/>
				<Grid container spacing={3}>

					{DataRegions.map(item => (

		        		<Grid item xs={6} sm={6}>
							<Card className={classes.rootcard} elevation={0} >
								<CardActionArea href={ "/promo/"+ item.slug }>
									<CardMedia
										className={classes.media}
										image={ item.image }
										title="Nasional"
									/>
									<CardContent style={{paddingLeft: 0}}>
										<Typography gutterBottom variant="h5" component="h2">
											{ item.name }
										</Typography>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>

			        ))}

				</Grid>
			</div>
	        
        </>
	)
}