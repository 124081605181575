import React, {useEffect, useState} from 'react';

import { Link, useParams, useHistory } from 'react-router-dom';

import API from "../Api.js";
import MetaTags from 'react-meta-tags';
import DOMPurify from 'dompurify'; 

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import AppBarColor from "../AppBarColor.js";

import CloseIcon from '@material-ui/icons/Close';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkIcon from '@material-ui/icons/Link';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    needspacesmall: {
    	marginTop: theme.spacing(2),
    },
    rootcard: {
	    width: '100%',
	    minHeight: 200,
	    display: "inline-block"
	},
	media: {
	    height: 170,
	},
	heading: {
	    fontSize: theme.typography.pxToRem(15),
	    fontWeight: theme.typography.fontWeightBold,
	},
}));

export default function Home() {

	const classes = useStyles();
	let { name } = useParams();
	let history = useHistory();

	const [DataPost, setDataPost] = useState([]);
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {

		// navigator.clipboard.writeText(API.base_url +"promo/detail/"+ DataPost.slug)

	    setOpen(true);
	};

	const handleClose = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }

	    setOpen(false);
	};

	useEffect(() => {

		ReactGA.initialize("G-0KQ2GK8TNB");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        ReactGA.initialize("G-M07HZ6V7VZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
		initiateData(name)

	}, [])

	function initiateData(name) {
		// console.log("https://geolocation-db.com/json");
		fetch("https://geolocation-db.com/json", {
			method: 'POST'
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log('siin'+result)
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  	// alert('No Promo Found')
			}
		)
		
		fetch(API.url + "posts/detail", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"slug": name
			}),
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result)
			  	setDataPost(result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  	alert('No Promo Found')
			}
		)

	}

	function isValidHttpUrl(string) {
	  let url;
	  
	  try {
	    url = new URL(string);
	  } catch (_) {
	    return false;  
	  }

	  return url.protocol === "http:" || url.protocol === "https:";
	}

	return (
		<>
			<MetaTags>
	            <title>BNI Mobile Site - { DataPost.title }</title>

	            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

	            <meta property="og:title" content={ "BNI Mobile Site - "+ DataPost.title } />
				<meta property="og:description" content={  DataPost.title + " " +  DataPost.subtitle } />
				<meta property="og:image" content={ DataPost.featured }/>
				<meta property="og:url" content={ API.base_url + window.location.pathname.substring(1) + window.location.search } />

				<meta name="twitter:title" content={ "BNI Mobile Site - "+ DataPost.title } />
				<meta name="twitter:description" content={  DataPost.title + " " +  DataPost.subtitle } />
				<meta name="twitter:image" content={ DataPost.featured } />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:site_name" content="BNI Mobile Site" />
				<meta property="og:title" content={ "BNI Mobile Site - "+ DataPost.title } />
				<meta property="og:description" content={  DataPost.title + " " +  DataPost.subtitle } />
				<meta property="og:image" content={ DataPost.featured } />
				<meta property="og:url" content={ API.base_url + window.location.pathname.substring(1) + window.location.search } />

	        </MetaTags>
			<div style={{ 
				paddingLeft: '20px',
				paddingRight: '20px',
			}}>
				<AppBarColor/>
				<span onClick={() => history.goBack()} style={{textDecoration: 'none', color: '#000'}}>
					<Grid container spacing={3}>
				        <Grid item xs={1} style={{paddingTop: 15}}>
				          	<ArrowBackIcon />
				        </Grid>
				        <Grid item xs={11}>
				        	<Typography variant="h6">
								 Back
							</Typography>
				        </Grid>
			        </Grid>
		        </span>
				{/*<Link to="/promo/terbaru">*/}
		        	<img alt="" src={ DataPost.featured } style={{width: '100%'}}/>
		        {/*</Link>*/}
		        <div className={classes.needspacesmall}/>
		        <Typography variant="h6">
					{ DataPost.title }
				</Typography>
				<Typography variant="subtitle1">
					{ DataPost.subtitle }
				</Typography>
				<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 }}/>
				<Grid container>
					<Grid item xs={1}>
						<TodayIcon /> 
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle1">
							{ DataPost.period }
						</Typography>
					</Grid>
				</Grid>
				<hr style={{ border: '1px dashed lightgray', marginTop: 15, marginBottom: 15 } }/>
				{/*<Typography variant="h6">
					T&C
				</Typography>*/}
				<div className={classes.needspacesmall}/>
				<div className={classes.root}>
					<div style={{textAlign: 'center'}}>
						{ [0].map((item) => {
			               if(DataPost.creditcard == 'yes'){
				               return (<><a href="http://bit.ly/applykartukreditbni"><img src={process.env.PUBLIC_URL + "/images/apply.png"} style={{width: '65%'}}/></a><br/></>
							)}
			            })}

			            { [0].map((item) => {
			               if(DataPost.saving == 'yes'){
				               return (<><a href="https://bukarekening.bni.co.id/simpanan/home"><img src={process.env.PUBLIC_URL + "/images/bukarek.png"} style={{width: '65%'}}/></a><br/></>
							)}
			            })}

			            { [0].map((item) => {
			               if(DataPost.griya == 'yes'){
				               return (<><a href="https://eform.bni.co.id/BNI_eForm/Disclaimer"><img src={process.env.PUBLIC_URL + "/images/griya.png"} style={{width: '65%'}}/></a><br/></>
							)}
			            })}

			            { [0].map((item) => {
			               if(DataPost.flexi == 'yes'){
				               return (<><a href="https://eform.bni.co.id/BNI_eForm/Disclaimer"><img src={process.env.PUBLIC_URL + "/images/fleksi.png"} style={{width: '65%'}}/></a></>
							)}
			            })}
					</div>
				    <Accordion className={`accordion-dropdown`} expanded={true}>
				        <AccordionSummary
				          expandIcon={<ExpandMoreIcon />}
				          aria-controls="panel1a-content"
				          id="panel1a-header"
				        >
				        <Typography className={classes.heading}>Syarat Dan Ketentuan</Typography>
				        </AccordionSummary>
				        <AccordionDetails>
				          <Typography style={{fontFamily: 'Montserrat', wordBreak: 'break-word'}}>
				            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(DataPost.content).replace(/&nbsp;/g, ' ') }} />
				          </Typography>
				        </AccordionDetails>
				    </Accordion>
				    <Accordion className={`accordion-dropdown`} expanded={true}>
				        <AccordionSummary
				          expandIcon={<ExpandMoreIcon />}
				          aria-controls="panel1a-content"
				          id="panel1a-header"
				        >
				        <Typography className={classes.heading}>Lokasi</Typography>
				        </AccordionSummary>
				        <AccordionDetails>
				          <Typography style={{fontFamily: 'Montserrat', wordBreak: 'break-word'}}>
				            
				            { [0].map((item) => {
				               if(isValidHttpUrl(DataPost.location)){
					               return (
					               	<>
					               		<a href={DataPost.location} target="_blank">{DataPost.location}</a>
					               	</>)
					           	}
					           	else
					            {
					            	return (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(DataPost.location).replace(/&nbsp;/g, ' ') }} />)
					            }

				            })}

				          </Typography>
				        </AccordionDetails>
				    </Accordion>
			    </div>

			    <br/>
			    <Typography className={classes.heading}>Share this: </Typography>
			    <table style={{paddingTop: 10}}>
				    <tr>
				    	<td style={{paddingRight: 10}}>
				    		<a href="javascript:;" onClick={handleClick}>
				    			<LinkIcon style={{color: '#000'}}/>
				    		</a>
				    	</td>
				    	<td style={{paddingRight: 10}}>
				    		<a href={"https://twitter.com/intent/tweet?url="+ API.base_url +"promo/detail/"+ DataPost.slug }>
				    			<TwitterIcon style={{color: '#000'}}/>
				    		</a>
				    	</td>
				    	<td style={{paddingRight: 10}}>
				    		<a href={"https://www.facebook.com/sharer/sharer.php?u="+ API.base_url +"promo/detail/"+ DataPost.slug }>
				    			<FacebookIcon style={{color: '#000'}}/>
				    		</a>
				    	</td>
				    	<td style={{paddingRight: 10}}>
				    		<a href={"https://wa.me/?text="+ API.base_url +"promo/detail/"+ DataPost.slug }>
				    			<WhatsAppIcon style={{color: '#000'}}/>
				    		</a>
				    	</td>
				    </tr>
			    </table>

				<br/>
				<br/>
				{/*<center>***</center>*/}
				<br/>
				<br/>
			</div>

			<Snackbar
		        anchorOrigin={{
		          vertical: 'bottom',
		          horizontal: 'center',
		        }}
		        open={open}
		        autoHideDuration={6000}
		        onClose={handleClose}
		        message="URL Berhasil dicopy."
		        action={
		          <React.Fragment>
		            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
		              <CloseIcon fontSize="small" />
		            </IconButton>
		          </React.Fragment>
		        }
		    />
        </>
	)
}